import { Props } from './TshirtSvg';
const ShortsSvg = ({
  fill,
  width,
  height,
  selectClothing,
  selected,
}: Props) => {
  return (
    <svg
      version="1.1"
      id="svg9"
      width={width}
      height={height}
      viewBox="0 0 1600 1600"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => selectClothing('shorts')}
      fill="red"
    >
      <defs id="defs13">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath25">
          <path d="M 0,1200 H 1200 V 0 H 0 Z" id="path23" />
        </clipPath>
      </defs>
      <g id="g17" transform="matrix(1.3333333,0,0,-1.3333333,0,1600)">
        <g id="g19">
          <g id="g21" clipPath="url(#clipPath25)">
            <g id="g27" transform="translate(107.6143,588.5977)">
              <path
                d="m 0,0 c 34.782,110.608 86.073,228.102 208.736,361.539 h 596.34 C 902.744,260.689 963.599,134.068 1013.812,0 892.844,-69.26 771.876,-138.518 650.904,-207.775 615.222,-105.133 571.04,-15.953 506.908,41.642 439.333,-21.924 399.517,-113.248 362.905,-207.775 241.936,-138.518 120.969,-69.26 0,0"
                style={{
                  fill: fill,
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path29"
              />
            </g>
            <g id="g31" transform="translate(906.3369,950.1372)">
              <path
                d="m 0,0 h 6.354 c 97.668,-100.851 158.522,-227.472 208.736,-361.539 l -27.66,-15.836 -30.444,25.144 C 112.663,-238.602 63.294,-133.802 0,-53.373 Z"
                style={{
                  fill: selected ? 'rgba(224, 100, 60, 1)' : '#869c0b',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path33"
              />
            </g>
            <g id="g35" transform="translate(107.6143,588.5977)">
              <path
                d="m 0,0 c 34.782,110.608 86.073,228.102 208.736,361.539 h 3.46 L 210.119,308.65 C 131.36,227.136 83.348,121.825 41.419,11.803 L 17.055,-9.768 Z"
                style={{
                  fill: selected ? 'rgba(224, 100, 60, 1)' : '#869c0b',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path37"
              />
            </g>
            <g id="g39" transform="translate(832.8661,423.3887)">
              <path
                d="M 0,0 -74.348,-42.566 C -110.03,60.076 -154.212,149.256 -218.344,206.851 -285.919,143.285 -325.735,51.961 -362.347,-42.566 l -52.612,30.123 -27.107,79.339 c 57.182,123.644 122.229,235.494 222.157,295.026 103,-64.843 176.708,-166.689 234.672,-288.416 z"
                style={{
                  fill: fill,
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path41"
              />
            </g>
            <g id="g43" transform="translate(582.207,595.3672)">
              <path
                d="m 0,0 c -13.343,-16.414 -25.473,-34.045 -36.678,-52.602 -26.716,41.817 -48.62,87.871 -64.438,139.282 -19.763,64.237 -29.914,136.681 -27.924,219.488 l 58.555,-1.377 C -72.316,228.667 -63.082,162.385 -45.091,103.903 -33.518,66.293 -18.281,31.84 0,0 m 102.228,-51.148 c -11.515,18.689 -23.85,36.076 -37.15,51.941 18.079,31.618 33.163,65.81 44.637,103.11 17.994,58.482 27.228,124.764 25.4,200.888 l 58.549,1.377 C 195.654,223.361 185.506,150.917 165.74,86.68 150.107,35.862 128.524,-9.721 102.228,-51.148"
                style={{
                  fill: fill,
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path45"
              />
            </g>
            <g id="g47" transform="translate(487.1025,663.6021)">
              <path
                d="M 0,0 C 13.955,19.626 28.716,38.04 44.477,54.949 46.219,48.429 48.065,42 50.014,35.668 61.586,-1.942 76.823,-36.395 95.104,-68.235 81.762,-84.649 69.632,-102.28 58.427,-120.836 34.947,-84.086 15.182,-44.061 0,0 m 197.332,-119.383 c -11.515,18.689 -23.849,36.076 -37.149,51.941 18.079,31.618 33.163,65.81 44.636,103.11 1.913,6.211 3.723,12.509 5.433,18.899 C 226.39,38.705 241.648,21.74 256.124,3.784 240.976,-41.189 221.075,-81.979 197.332,-119.383"
                style={{
                  fill: fill,
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path49"
              />
            </g>
            <g id="g51" transform="translate(107.6143,588.5977)">
              <path
                d="m 0,0 c 5.309,16.883 11.005,33.929 17.277,51.165 37.742,4.039 88.636,-8.609 141.59,-31.603 27.151,-11.793 54.941,-25.754 81.235,-40.773 26.822,-15.322 52.679,-31.994 75.421,-48.893 31.511,-23.41 57.323,-48.316 73.552,-73.019 -9.093,-21.178 -17.717,-42.828 -26.17,-64.652 l -36.403,20.843 c 0.066,0.043 0.122,0.092 0.156,0.153 1.181,2.043 -25.449,23.023 -60.996,49.429 -19.987,14.854 -42.93,29.625 -66.892,43.315 -24.495,13.99 -49.485,26.611 -73.037,36.84 -47.159,20.48 -85.622,36.423 -89.217,36.291 z"
                style={{
                  fill: '#f5ac00',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path53"
              />
            </g>
            <g id="g55" transform="translate(107.6143,588.5977)">
              <path
                d="M 0,0 C 5.309,16.883 11.005,33.929 17.277,51.165 29.144,52.436 42.313,52.054 56.436,50.222 51.322,37.498 46.328,24.683 41.419,11.803 L 17.055,-9.768 Z"
                style={{
                  fill: '#ffcc00',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path57"
              />
            </g>
            <g id="g59" transform="translate(409.043,528.6621)">
              <path
                d="m 0,0 c 4.81,-3.375 9.517,-6.766 14.095,-10.168 31.51,-23.41 57.322,-48.316 73.551,-73.02 -9.092,-21.177 -17.716,-42.828 -26.169,-64.652 l -36.404,20.844 c 0.067,0.043 0.122,0.092 0.156,0.152 0.634,1.1 -6.766,7.666 -19.264,17.608 l -24.208,70.859 C -12.261,-25.439 -6.191,-12.633 0,0"
                style={{
                  fill: selected ? 'rgba(224, 100, 60, 1)' : '#e39400',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path61"
              />
            </g>
            <g id="g63" transform="translate(107.6143,588.5977)">
              <path
                d="M 0,0 C 36.645,63.472 404.865,-135.092 362.905,-207.775 320.945,-280.455 -36.648,-63.477 0,0"
                style={{
                  fill: '#536102',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path65"
              />
            </g>
            <g id="g67" transform="translate(942.4619,1042.6216)">
              <path
                d="m 0,0 -655.886,-0.001 v -125.31 c 0,-26.014 146.826,-47.101 327.947,-47.101 181.116,0 327.939,21.087 327.939,47.101 z"
                style={{
                  fill: '#f5ac00',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path69"
              />
            </g>
            <g id="g71" transform="translate(942.4619,1042.6216)">
              <path
                d="m 0,0 h -41.509 l 5.384,-21.513 v -124.344 l 0.616,-0.79 C -12.807,-140.242 0,-132.992 0,-125.311 Z"
                style={{
                  fill: '#e39400',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path73"
              />
            </g>
            <g id="g75" transform="translate(323.4443,1042.6206)">
              <path
                d="m 0,0 h -36.868 v -125.31 c 0,-7.175 11.184,-13.97 31.157,-20.058 z"
                style={{
                  fill: '#ffcc00',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path77"
              />
            </g>
            <g id="g79" transform="translate(614.5225,995.52)">
              <path
                d="M 0,0 C 180.622,0 327.939,21.159 327.939,47.102 327.939,73.046 180.622,94.205 0,94.205 -180.629,94.205 -327.943,73.046 -327.943,47.101 -327.943,21.159 -180.629,0 0,0"
                style={{
                  fill: '#6f8207',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path81"
              />
            </g>
            <g id="g83" transform="translate(1101.4248,640.021)">
              <path
                d="m 0,0 c 6.879,-16.991 13.53,-34.146 20.002,-51.423 -120.969,-69.26 -241.934,-138.52 -362.908,-207.776 -7.921,22.791 -16.265,44.916 -25.152,66.18 16.282,24.203 41.674,48.563 72.536,71.492 22.743,16.899 48.6,33.571 75.421,48.893 26.292,15.019 54.085,28.98 81.236,40.774 C -87.173,-9.411 -37.444,3.171 0,0"
                style={{
                  fill: '#f5ac00',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path85"
              />
            </g>
            <g id="g87" transform="translate(1101.4248,640.021)">
              <path
                d="m 0,0 c 6.879,-16.991 13.53,-34.146 20.002,-51.423 l -27.66,-15.836 -30.444,25.144 c -5.004,12.832 -10.076,25.547 -15.219,38.129 C -33.871,-0.136 -15.837,1.339 0,0"
                style={{
                  fill: '#e39400',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path89"
              />
            </g>
            <g id="g91" transform="translate(832.8661,423.3867)">
              <path
                d="m 0,0 -74.348,-42.564 c -7.92,22.791 -16.264,44.916 -25.151,66.179 16.281,24.203 41.674,48.563 72.536,71.492 7.32,5.44 14.96,10.854 22.852,16.207 C 2.366,98.943 8.653,86.338 14.763,73.508 Z"
                style={{
                  fill: selected ? 'rgba(224, 100, 60, 1)' : '#e39400',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path93"
              />
            </g>
            <g id="g95" transform="translate(1121.4268,588.5977)">
              <path
                d="M 0,0 C -36.648,63.473 -404.868,-135.092 -362.908,-207.775 -320.945,-280.455 36.648,-63.477 0,0"
                style={{
                  fill: '#536102',
                  fillOpacity: 1,
                  fillRule: 'evenodd',
                  stroke: 'none',
                }}
                id="path97"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShortsSvg;
